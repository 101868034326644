import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardText,[_c(VRow,{staticClass:"ml-2"},[_c(VCol,{staticClass:"d-flex flex-column justify-space-between align-center",attrs:{"cols":"12"}},[_c(VImg,{attrs:{"src":_vm.persongem,"width":"480px"}}),_c('h2',[_vm._v("Obrigado pela participação")]),_c('p',[_vm._v(_vm._s(_vm.message))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }