<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="800">
      <v-card>
        <v-card-text>
          <v-row class="ml-2">
            <v-col
                cols="12"
                class="d-flex flex-column justify-space-between align-center"
            >
                <v-img :src="persongem" width="480px"></v-img>
                <h2>Obrigado pela participação</h2>
                <p>{{ message }}</p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import imagem from "@/assets/images/svg/finish.svg";
export default {
  name: "modal-pesquisa-finalizada",
  data: () => ({
    persongem: imagem
  }),
  computed: {
    dialog: {
      get() {
        return this.$store.getters["pesquisas/getPesquisaVencidaDialogExterna"];
      },
      set(value) {
        this.$store.dispatch("pesquisas/setPesquisaVencidaDialogExterna", value);
      },
    },
    message: {
      get() {
        return this.$store.getters["pesquisas/getPesquisaExternaMessage"];
      },
      set(value) {
        this.$store.dispatch("pesquisas/setPesquisaExternaMessage", value);
      },
    }
  },
};
</script>

<style>
    svg#freepik_stories-self-confidence:not(.animated) .animable {
      opacity: 0;
    }
    svg#freepik_stories-self-confidence.animated #freepik--hand-4--inject-20 {
      animation: 1.5s Infinite linear heartbeat;
      animation-delay: 0s;
    }
    svg#freepik_stories-self-confidence.animated #freepik--hand-3--inject-20 {
      animation: 1.5s Infinite linear heartbeat;
      animation-delay: 0s;
    }
    svg#freepik_stories-self-confidence.animated #freepik--hand-2--inject-20 {
      animation: 1.5s Infinite linear heartbeat;
      animation-delay: 0s;
    }
    svg#freepik_stories-self-confidence.animated #freepik--hand-1--inject-20 {
      animation: 1.5s Infinite linear heartbeat;
      animation-delay: 0s;
    }
    @keyframes heartbeat {
      0% {
        transform: scale(1);
      }
      10% {
        transform: scale(1.1);
      }
      30% {
        transform: scale(1);
      }
      40% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
      60% {
        transform: scale(1);
      }
      100% {
        transform: scale(1);
      }
    }
</style>
